import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component
(
	{
		selector: 'ui-sidebar',
		templateUrl: './sidebar.component.html',
		imports:
		[
			MatButtonModule,
			MatIconModule,
			MatProgressSpinnerModule
		]
	}
)

export class SidebarComponent
{
	readonly title = input<string>('');
	readonly isFormValid = input<boolean>(false);
	readonly isFormDisabled = input<boolean>(false);

	readonly saveButtonLabel = input<string>('Save');
	readonly cancelButtonLabel = input<string>('Cancel');

	readonly showActionBar = input<boolean>(true);
	readonly showCancelButton = input<boolean>(true);
	readonly showSaveButton = input<boolean>(true);
	readonly showTitleBar = input<boolean>(true);

	readonly onSave = output();
	readonly onCancel = output();

	public save()
	{
		this.onSave.emit();
	}

	public cancel()
	{
		this.onCancel.emit();
	}
}
